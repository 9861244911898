import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/powergeneration/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";
import img1 from "../../images/powergeneration/1.jpg";
import img2 from "../../images/powergeneration/2.jpg";
import img3 from "../../images/powergeneration/3.jpg";
import img4 from "../../images/powergeneration/4.jpg";
import img5 from "../../images/powergeneration/5.jpg";
import img6 from "../../images/powergeneration/6.jpg";
import img7 from "../../images/powergeneration/7.jpg";
import img8 from "../../images/powergeneration/8.jpg";
import img9 from "../../images/powergeneration/9.jpg";
import img10 from "../../images/powergeneration/10.jpg";
import img11 from "../../images/powergeneration/11.jpg";
import img12 from "../../images/powergeneration/12.jpg";
import img13 from "../../images/powergeneration/13.jpg";
import img14 from "../../images/powergeneration/14.jpg";
import img15 from "../../images/powergeneration/15.jpg";
import img16 from "../../images/powergeneration/16.jpg";
import img17 from "../../images/powergeneration/17.jpg";
import img18 from "../../images/powergeneration/18.jpg";
import img19 from "../../images/powergeneration/19.jpg";

const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
    {
        original: img6,
    },
    {
        original: img7,
    },
    {
        original: img8,
    },
    {
        original: img9,
    },
    {
        original: img10,
    },
    {
        original: img11,
    },
    {
        original: img12,
    },
    {
        original: img13,
    },
    {
        original: img14,
    },
    {
        original: img15,
    },
    {
        original: img16,
    },
    {
        original: img17,
    },
    {
        original: img18,
    },
    {
        original: img19,
    },
];
const Electrical = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Power Generation"}
            navSize={"h-110 xs:h-96"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-18 sm:mt-20 mt-24"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Power Generation Services
                </title>
                <meta
                    name="description"
                    content="Electrical contracting, design and installation, de-commissioning, commissioning, relocation, electrical upgrades and preventative maintenance."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-lightgray"}>
                <p
                    className={
                        "text-center font-exo2 leading-8 text-xl py-5"
                    }
                >
                    E-P-G Solutions offers a range of power generation services suitable for your needs no matter where you are in the Northern Territory. We have experience in the design and installation of small household-size generators right up to complete diesel and gas fired power stations and can find a solution for you.
                </p>
            </SectionLayout>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white pt-12 pb-8"}>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We focus on adding reliability and efficiency to power generation by
                        incorporating automation into our systems. That includes designing
                        control systems that provide automatic switching between utility and
                        standby power in the event of a power failure.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We are experienced in ensuring the safe and efficient operation of
                        existing systems as well with maintenance and repairs (preventative
                        and as needed) and load testing to identify current and potential
                        issues.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Our services include:
                    </p>
                    <ul className={"list-disc grid lg:leading-8 lg:text-lg lg:grid-cols-2 p-4 leading-7 font-exo2 text-epg-black"}>
                        <div>
                            <li>Standby power and prime power</li>
                            <li>Generator control and protection</li>
                            <li>Generator maintenance and repairs</li>
                        </div>
                       <div>
                           <li>Generator load testing</li>
                           <li>Automated changeover systems</li>
                           <li>LV and HV test and protection</li>
                       </div>
                    </ul>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We don’t believe in installing a system and then walking away. We are
                        available 24-7 to provide advice, support and troubleshooting on any
                        of our systems.
                    </p>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default Electrical;
